const useDialog = () => {
  const isDialogOpen = ref(false);

  const openDialog = () => {
    isDialogOpen.value = true;
  };

  const closeDialog = () => {
    isDialogOpen.value = false;
  };

  return {
    closeDialog,
    isDialogOpen,
    openDialog,
  };
};

export { useDialog };
